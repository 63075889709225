import React from 'react';

import Calidad from './../assets/img/calidad-min.jpg';
import Precio from './../assets/img/precio-min.jpg';
import Confeccion from './../assets/img/confeccion-min.jpg';
import Cumplimiento from './../assets/img/cumplimiento-min.jpg';

export default () => {
    return (
        <React.Fragment>
            <section className="container features" id="us">
                <div className="col-md-3 item">
                    <img src={Calidad} alt="" />
                    <h3>CALIDAD</h3>
                    <p>Los mejores materiales y cuidado en cada etapa del proceso, garantiza nuestra calidad en cada uno de los productos.</p>
                </div>
                <div className="col-md-3 item">
                    <img src={Precio} alt="" />
                    <h3>PRECIO</h3>
                    <p>Precios competitivos que reflejan la calidad, durabilidad, diseño y calidad de cada prenda.</p>
                </div>
                <div className="col-md-3 item">
                    <img src={Confeccion} alt="" />
                    <h3>CONFECCIÓN</h3>
                    <p>Personal altamente capacitado en alta costura y diseño aseguran la mas alta calidad en el corte y confección de los productos.</p>
                </div>
                <div className="col-md-3 item">
                    <img src={Cumplimiento} alt="" />
                    <h3>CUMPLIMIENTO</h3>
                    <p>Entregamos a tiempo, manejamos stocks dependiendo de la frecuencia de compra y necesidad del cliente.</p>
                </div>
            </section>
        </React.Fragment>
    );
}