import React from 'react';
import NavBar from './../components/navbar';
import HeroImg from './../components/hero-image';
import Features from './../components/features';
import Galery from './../components/galery';
import Clients from './../components/clients';
import Contact from './../components/contact';
import Footer from './../components/footer';

class Landing extends React.Component {
    render() {
        return (
            <React.Fragment>
                <NavBar />
                <HeroImg />
                <Features />
                <Galery />
                <Clients />
                <Contact />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Landing;