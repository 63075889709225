import React from 'react';
import logo from './../assets/img/logo-min.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default () => {
    return (
        <React.Fragment>
            <nav className="navbar navbar-default header">
                <div id="home" className="top-bar"></div>
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/">
                            <img src={logo} className="visible-lg" alt="" />
                            <span className="visible-xs text-on-logo">Unidotaciones P&A SAS</span>
                        </a>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right">
                            <li><AnchorLink href="#home">Home</AnchorLink></li>
                            <li><AnchorLink href="#us">Nosotros</AnchorLink></li>
                            <li><AnchorLink href="#clients">Nuestros Clientes</AnchorLink></li>
                            <li><AnchorLink href="#contact">Solicite un estimado</AnchorLink></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}
