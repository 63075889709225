import React from 'react';
import { Carousel } from 'react-bootstrap';

import Prenda1 from './../assets/img/prenda1-min.jpg';
import Prenda2 from './../assets/img/prenda2-min.jpg';
import Prenda3 from './../assets/img/prenda3-min.jpg';
import Prenda4 from './../assets/img/prenda4-min.jpg';
import Prenda5 from './../assets/img/prenda5-min.jpg';
import Prenda6 from './../assets/img/prenda6-min.jpg';

export default () => {
    return (
        <React.Fragment>
            <section className="container clothes">
                <Carousel indicators={false}>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-4">
                                <img src={Prenda1} alt="Camiseta Dotacion Unidotaciones" />
                            </div>
                            <div className="col-md-4">
                                <img src={Prenda2} alt="Chaqueta deportiva Unidotaciones" />
                            </div>
                            <div className="col-md-4">
                                <img src={Prenda3} alt="Buso Dotacion Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-4">
                                <img src={Prenda4} alt="Camisa Dotacion Unidotaciones" />
                            </div>
                            <div className="col-md-4">
                                <img src={Prenda5} alt="Chaqueta prom Unidotaciones" />
                            </div>
                            <div className="col-md-4">
                                <img src={Prenda6} alt="Chaleco Dotacion Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </section>

            <section className="container clothes hidden-md hidden-lg">
                <Carousel>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-12">
                                <img src={Prenda1} alt="Camiseta Dotacion Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-12">
                                <img src={Prenda2} alt="Chaqueta deportiva Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-12">
                                <img src={Prenda3} alt="Buso Dotacion Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-12">
                                <img src={Prenda4} alt="Camisa Dotacion Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-12">
                                <img src={Prenda5} alt="Chaqueta prom Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row">
                            <div className="col-md-12">
                                <img src={Prenda6} alt="Chaleco Dotacion Unidotaciones" />
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </section>
        </React.Fragment>
    );
}