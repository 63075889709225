import React from 'react';

import LogoFooter from './../assets/img/logo-footer-min.png';
import Envelope from './../assets/img/envelope-min.jpg';
import Phone from './../assets/img/phone-min.jpg';
import Marker from './../assets/img/marker-min.jpg';

export default () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <img src={LogoFooter} alt="" />
                        </div>
                        <div className="col-md-8 text-center contact-info">
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={Envelope}  alt=""/> <br />
                                    marketing@unidotaciones.com
                                </div>
                                <div className="col-md-4">
                                    <img src={Phone}  alt=""/> <br />
                                    (571) 218-3730 - 304-462-1347
                                </div>
                                <div className="col-md-4">
                                    <img src={Marker}  alt=""/> <br />
                                    CRA 74B No 51A - 50 Normandía, Bogotá, Colombia
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}