import React from 'react';
import { Form, Field } from 'react-final-form'
import axios from 'axios';
import Swal from 'sweetalert2';

const onSubmit = async values => {
    window.grecaptcha.execute('6LdUc7EUAAAAAJbGn6QH7-IGsvxQQr_k6pqt-Gm3', {action: 'contact'})
        .then(function(token) {
            values.token = token;
            axios({
                url: '/contact-send',
                method: 'post',
                data: values
            }).then(res => {
                Swal.fire(
                    'Gracias!',
                    'Hemos recibido tu mensaje y estaremos en contacto!',
                    'success'
                )
            }).catch(error => {
                Swal.fire(
                    'Ups!',
                    'Ha ocurrido un error, por favor intentalo de nuevo',
                    'error'
                )    
            });
        });  
}

function  validEmail (val) {
    if (val && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) {
        return true;
    }

    return false;
};

const validate = (values) => {
    const errors = {};

    if (!values.name || values.name === '') {
        errors.name = 'Dinos tu nombre';
    }

    if (!values.phone_number || values.phone_number === '') {
        errors.phone_number = 'Dinos tu teléfono';
    }

    if (!values.email || values.email === '') {
        errors.email = 'Dinos tu e-mail';
    }

    if (!validEmail(values.email)) {
        errors.email = 'Tu email no es valido.';
      }

    return errors;
  };

export default () => {
    return (        
        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, pristine, invalid }) => (
                <section className="contact" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2 className="contact-title"><strong>SOLICITE</strong> UN ESTIMADO</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1">
                                <div className="row">
                                    <form id="contact-form" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        NOMBRE *
                                                    </label>
                                                    <div className="controls">
                                                        <Field
                                                            name="name"
                                                            component="input"
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        TELÉFONO *
                                                    </label>
                                                    <div className="controls">
                                                        <Field
                                                            name="phone_number"
                                                            component="input"
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        E-MAIL *
                                                    </label>
                                                    <div className="controls">
                                                        <Field
                                                            name="email"
                                                            component="input"
                                                            type="email"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        MENSAJE *
                                                    </label>
                                                    <div className="controls">
                                                        <Field
                                                            name="msj"
                                                            component="textarea"
                                                            className="form-control"
                                                            rows="5"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <br />
                                                <button className="btn btn-default" type="submit" disabled={pristine || invalid}>Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        />
    );
}