import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default () => {
    return (
        <React.Fragment>
            <section className="hero-image">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12 hero-text">
                            <span className="text1">Diseñamos</span>
                            <span className="text2">y confeccionamos</span>
                            <span className="text3">la imagen de tu empresa</span>
                            <AnchorLink href="#contact"><button className="btn btn-default">SOLICITE UN ESTIMADO</button></AnchorLink>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}