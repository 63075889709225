import React from 'react';
import { Carousel } from 'react-bootstrap';

import CustomerImg from './../assets/img/customer-min.jpg';
import Indusel from './../assets/img/logo-indusel-min.jpg';
import LaNana from './../assets/img/logo-la-nana-min.jpg';
import Liceo from './../assets/img/logo-liceo-min.jpg';

export default () => {
    return (
        <React.Fragment>
            <section className="container quotes" id="clients">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <img src={CustomerImg} className="hidden-xs" alt=""/>
                    </div>
                    <div className="col-md-6 col-xs-12 text-center">
                        <h2 className="quotes-title"><strong>NUESTROS</strong> CLIENTES</h2>
                        <Carousel className="quotes-carousel" controls={false}>
                            <Carousel.Item>
                                "Unidotaciones ha sido una empresa muy cumplida y nos a ayudado mucho en la selección y fabricación de los uniformes adecuados para nuestros promotores a nivel nacional, realmente estamos muy felices de poder contar con ellos" <br /> - Adriana Forero - Indusel
                            </Carousel.Item>
                            <Carousel.Item>
                                "Teniamos muchos problemas con los uniformes de nuestro jardin, pero desde que Unidotaciones nos empezo a ayudar los uniformes de los niños y las profes mejoraron mucho en calidad, precios y tiempos de entrega." <br /> - Jardin La Nana
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </section>
            <section className="line-separator"></section>
            <section className="container client-logos">
                <div className="col-md-4">
                    <img src={Indusel} alt=""/>
                </div>
                <div className="col-md-4">
                    <img src={LaNana} alt="" />
                </div>
                <div className="col-md-4">
                    <img src={Liceo} alt="" />
                </div>
            </section>
            <section className="line-separator"></section>
        </React.Fragment>
    );
}